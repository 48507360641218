import ContentContainer from './ContentContainer';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { buildQueryString, getOrderCount, getOrders } from './requests';
import {
  FormSubmitCountGetResponse,
  FormSubmitsGetResponse,
  LoadingState,
  Order,
  OrderFilterRequest,
  SearchResultsResponse
} from './types';
import { getUserFullName, isAdminUser, isViewUser } from './utils';
import { DateTime } from 'luxon';
import { NavigationContext } from './NavigationBar';
import { getUserDataStorage } from './storageHelper';
import { useApi } from './Forms/DynamicForms/fetchHook';
import { Alert } from '@material-ui/lab';
import { Favorite } from '@material-ui/icons';
import OrderFormRedirectMessage from './Forms/OrderFormRedirectMessage';

interface DashboardInfoCardProps {
  title: string;
  value: string;
  body?: JSX.Element;
}

const useStyles = makeStyles((theme) => ({
  green: {
    color: 'green'
  },
  red: {
    color: 'red'
  },
  gray: {
    color: '#3a3a3a'
  },
  link: {
    '&:hover': {
      textDecoration: 'none'
    }
  },
  mainButton: {
    width: '100%',
    marginTop: 10,
    [theme.breakpoints.up('sm')]: {
      width: 250
    }
  },
  buttonContainer: {
    maxWidth: 1440,
    margin: '0 auto',
    paddingLeft: 24,
    paddingRight: 24
  },
  disabled: {
    cursor: 'not-allowed'
  }
}));

const DashboardInfoCard: React.FC<DashboardInfoCardProps> = ({
  title,
  value,
  body
}) => {
  const classes = useStyles();
  return (
    <div style={{ textAlign: 'center', flex: 1 }}>
      <Typography color="textSecondary">{title}</Typography>
      <Typography variant="h4" className={classes.gray}>
        {value}
      </Typography>
      {body}
    </div>
  );
};

const isBetween = (start: DateTime, end: DateTime, date: DateTime) => {
  const isAfterStart = date.diff(start).milliseconds >= 0;
  const isBeforeEnd = date.diff(end).milliseconds <= 0;
  return isAfterStart && isBeforeEnd;
};

const countOrders = (
  orders: Order[],
  timeFilter: (created: DateTime) => boolean
) => orders.filter((o) => timeFilter(DateTime.fromISO(o.created_at))).length;

const countMyOrders = (
  userName: string,
  orders: Order[],
  timeFilter: (created: DateTime) => boolean
) =>
  countOrders(
    orders.filter((o) => o.userName === userName),
    timeFilter
  );

const yesterdayFilter = (dateTime: DateTime) =>
  isBetween(
    DateTime.local().minus({ days: 1 }).startOf('day'),
    DateTime.local().startOf('day'),
    dateTime
  );

const todayFilter = (dateTime: DateTime) =>
  isBetween(DateTime.local().startOf('day'), DateTime.local(), dateTime);

const filterToday = (value: string) =>
  DateTime.fromISO(value) > DateTime.local().startOf('day');

const filterYesterday = (value: string) =>
  DateTime.fromISO(value) >
    DateTime.local().minus({ days: 1 }).startOf('day') &&
  DateTime.fromISO(value) < DateTime.local().startOf('day');

function Main() {
  const classes = useStyles();
  const isMobileView = useMediaQuery('(max-width:640px)');
  const [myOrderCountYesterday, setMyOrderCountYesterday] = React.useState(0);
  const [myOrderCountToday, setMyOrderCountToday] = React.useState(0);
  const [orderCount, setOrderCount] = React.useState(0);
  const [formList, isLoading, error] = useApi<SearchResultsResponse>(
    buildQueryString({ favoritesOnly: true })
  );

  const navigation = useContext(NavigationContext);

  const [totalOrdersCountYesterday, setTotalOrdersCountYesterday] =
    React.useState(0);
  const [totalOrdersCountToday, setTotalOrdersCountToday] = React.useState(0);
  const [formSubmits] = useApi<FormSubmitsGetResponse>(
    `/api/submits?filledBy=${getUserDataStorage().email}`
  );
  const [formCount] = useApi<FormSubmitCountGetResponse>(
    `/api/submits/count?filledBy=${getUserDataStorage().email}&submitted=true`
  );
  const [incompleteSubmitsCount] = useApi<FormSubmitCountGetResponse>(
    `/api/submits/count?filledBy=${getUserDataStorage().email}&submitted=false`
  );

  const [showOrderForm] = useApi<any>(`/api/show-order-form?key=showOrderForm`);
  const storage = localStorage.getItem('showOrderForm') === 'true';

  const disable = showOrderForm ?? storage;

  useEffect(() => {
    if (showOrderForm !== null) {
      if (showOrderForm !== storage) {
        localStorage.setItem('showOrderForm', showOrderForm);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOrderForm]);

  const completeSubmits = formSubmits
    ? formSubmits.results.filter((submit) => submit.submitted === true)
    : null;
  const incompleteSubmits = formSubmits
    ? formSubmits.results.filter((submit) => submit.submitted === false)
    : null;

  useEffect(() => {
    (async () => {
      const rangeFilterStart: OrderFilterRequest = {
        param: 'startDate',
        value: DateTime.local()
          .minus({ days: 1 })
          .startOf('day')
          .toISO({ includeOffset: false })
      };
      const rangeFilterEnd: OrderFilterRequest = {
        param: 'endDate',
        value: DateTime.local().toISO({ includeOffset: false })
      };
      const limitFilter: OrderFilterRequest = { value: '1000', param: 'limit' };
      getOrderCount().then((countReponse) => {
        setOrderCount(countReponse.count);
      });
      const totalOrders = await getOrders([
        rangeFilterStart,
        rangeFilterEnd,
        limitFilter
      ]);
      const userName = getUserFullName();
      setMyOrderCountYesterday(
        countMyOrders(userName, totalOrders, yesterdayFilter)
      );
      setMyOrderCountToday(countMyOrders(userName, totalOrders, todayFilter));
      setTotalOrdersCountYesterday(countOrders(totalOrders, yesterdayFilter));
      setTotalOrdersCountToday(countOrders(totalOrders, todayFilter));
    })();
  }, []);
  return (
    <div style={{ paddingBottom: 104 }}>
      {navigation === 0 && (
        <>
          <ContentContainer>
            <Box marginBottom={4}>
              <Typography variant="h5" className={classes.gray}>
                RamiForms-lomakkeet
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-evenly" mb={2}>
                <DashboardInfoCard
                  title="Lomakkeeni tänään"
                  value={
                    completeSubmits
                      ? completeSubmits
                          .map((result) => result.updatedAt)
                          .filter(filterToday)
                          .length.toString()
                      : '-'
                  }
                />
                {!isMobileView && (
                  <DashboardInfoCard
                    title="Lomakkeeni eilen"
                    value={
                      completeSubmits
                        ? completeSubmits
                            .map((result) => result.updatedAt)
                            .filter(filterYesterday)
                            .length.toString()
                        : '-'
                    }
                  />
                )}
                <DashboardInfoCard
                  title="Lomakkeeni yhteensä"
                  value={formCount?.count ?? '-'}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-evenly"
                alignItems="flex-start"
              >
                <DashboardInfoCard
                  title="Keskeneräiset lomakkeeni"
                  value={
                    incompleteSubmits
                      ? incompleteSubmits
                          .filter(
                            (submit) =>
                              submit.filledBy === getUserDataStorage().email
                          )
                          .length.toString()
                      : '-'
                  }
                />
                {isAdminUser() || isViewUser() ? (
                  <DashboardInfoCard
                    title="Keskeneräiset lomakkeet yhteensä"
                    value={incompleteSubmitsCount?.count ?? '-'}
                  />
                ) : (
                  <DashboardInfoCard title="" value="" />
                )}
                {/* This is only to help other dashboardinfocards to align better */}
                {!isMobileView && <DashboardInfoCard title="" value="" />}
              </Box>
            </Box>
          </ContentContainer>
          <Grid className={classes.buttonContainer}>
            <Link
              component={RouterLink}
              to="/lomakkeet"
              className={classes.link}
            >
              <Button
                className={classes.mainButton}
                variant="contained"
                color="primary"
              >
                Lomakelista
              </Button>
            </Link>
            <Box display="inline" marginLeft={isMobileView ? 0 : 2}>
              <Link
                component={RouterLink}
                to="/vastaukset"
                className={classes.link}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.mainButton}
                >
                  Täytetyt lomakkeet
                </Button>
              </Link>
            </Box>
            <Box display="inline" marginLeft={isMobileView ? 0 : 2}>
              <Link
                component={RouterLink}
                to="/keskeneraiset"
                className={classes.link}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.mainButton}
                >
                  Keskeneräiset lomakkeet
                </Button>
              </Link>
            </Box>
          </Grid>
        </>
      )}
      {navigation === 1 && (
        <>
          <ContentContainer>
            <Typography variant="h5" className={classes.gray}>
              Tilaustietolomake & RamiSmart-tilaukset
            </Typography>
            <Box marginBottom={4}></Box>
            <Box display="flex" justifyContent="space-between">
              <DashboardInfoCard
                title="Tilaukseni tänään"
                value={myOrderCountToday.toString()}
                body={
                  <Typography color="textSecondary" variant="body2">
                    {totalOrdersCountToday} yhteensä
                  </Typography>
                }
              />
              {!isMobileView && (
                <DashboardInfoCard
                  title="Tilaukseni eilen"
                  value={myOrderCountYesterday.toString()}
                  body={
                    <Typography color="textSecondary" variant="body2">
                      {totalOrdersCountYesterday} yhteensä
                    </Typography>
                  }
                />
              )}
              <DashboardInfoCard
                title="Tilaukset yhteensä"
                value={orderCount.toString()}
                body={
                  <Typography color="textSecondary" variant="body2">
                    26.05.2020 lähtien
                  </Typography>
                }
              />
            </Box>
          </ContentContainer>
          <Box className={classes.buttonContainer}>
            {disable === false ? <OrderFormRedirectMessage /> : null}
            <Link
              component={RouterLink}
              to="/tilaustieto"
              className={`${classes.link} ${!disable ? classes.disabled : ''}`}
              onClick={(event: any) => {
                if (!disable) {
                  event.preventDefault();
                }
              }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.mainButton}
                disabled={!disable}
              >
                Täytä uusi tilaus
              </Button>
            </Link>
            <Box display="inline" marginLeft={isMobileView ? 0 : 2}>
              <Link
                component={RouterLink}
                to="/tilaukset"
                className={classes.link}
              >
                <Button color="primary" className={classes.mainButton}>
                  Vastaanotetut tilaukset
                </Button>
              </Link>
            </Box>
          </Box>
        </>
      )}
      {navigation === 2 && (
        <ContentContainer>
          <Box marginBottom={2}>
            <Typography variant="h5" className={classes.gray}>
              Suosikit
            </Typography>
          </Box>
          {isLoading === LoadingState.IsLoading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={24} />
            </Box>
          )}
          {error && (
            <Alert severity="error">Virhe ladattaessa suosikkeja</Alert>
          )}
          {isLoading !== LoadingState.IsLoading && !error && formList && (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              style={{ backgroundColor: '#FFFFFF' }}
            >
              {formList.results.length === 0 ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography color="textSecondary">
                    Et ole lisännyt yhtään suosikkia. Voit lisätä lomakkeen
                    suosikiksi painamalla{' '}
                    <Favorite style={{ marginBottom: -6 }} />
                    -ikonia lomakelistassa.
                  </Typography>
                </Grid>
              ) : (
                formList?.results.map((form) => {
                  return (
                    <Grid style={{ marginBottom: 16 }} key={form.formId}>
                      <Link
                        component={RouterLink}
                        to={`/lomake/${form.formId}`}
                      >
                        <Box>
                          <Typography color="primary">{`${form.formId} | ${form.formName}`}</Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle2" color="textPrimary">
                            {form.description}
                          </Typography>
                        </Box>
                      </Link>
                    </Grid>
                  );
                })
              )}
            </Grid>
          )}
        </ContentContainer>
      )}
    </div>
  );
}

export default Main;
