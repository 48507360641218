import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Chip,
  makeStyles,
  Select,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import {
  DataListEntry,
  FilterParam,
  FilterParamProps,
  OrderFilter
} from '../types';
import { Link as RouterLink } from 'react-router-dom';
import OrderFormRedirectMessage from '../Forms/OrderFormRedirectMessage';

const useStyles = makeStyles((theme) => ({
  filterRow: {
    marginTop: 40
  },
  filterValueInput: {
    width: '100%'
  },
  autoCompleteOffice: {
    width: 250,
    marginBottom: theme.spacing(1)
  },
  filterContainer: {
    paddingRight: theme.spacing(1)
  },
  newOrderButton: {
    marginTop: theme.spacing(4)
  },
  link: {
    textDecoration: 'none'
  },
  disabled: {
    cursor: 'not-allowed'
  }
}));

const mapOfficeFilter = (officeFilter: string) =>
  ({
    label:
      officeFilter === 'ShowAllOffices' ? 'Kaikki toimipisteet' : officeFilter,
    value: officeFilter
  } as DataListEntry);

const getFilterLabel = (orderFilter: OrderFilter) => {
  switch (orderFilter.param) {
    case 'sourceSystem': {
      return 'Tilaus tehty';
    }
    case 'seller': {
      return 'Myyjä';
    }
    case 'office': {
      return 'Toimipiste';
    }
    case 'userName': {
      return 'Tilauksen täyttäjä';
    }
    case 'siteId': {
      return 'Työnumero';
    }
    case 'ramiRentOrderNumber': {
      return 'Tilausnumero';
    }
    default: {
      return orderFilter.param;
    }
  }
};

interface OrderFiltersParams {
  onFilterSet: () => void;
  onFilterParamChange: (value: FilterParam) => void;
  onFilterValueChange: (value: string) => void;
  onRamiSmartFilterToggle: (value: boolean) => void;
  onMyOrdersFilterToggle: (value: boolean) => void;
  onOfficeChange: (value: string) => void;
  onResetFilterClick: () => void;
  onFilterDelete: (orderFilter: OrderFilter) => void;
  filterValue: string;
  ramiSmartFilter: boolean;
  myOrdersFilter: boolean;
  officeList: DataListEntry[];
  officeFilter: string;
  filterParams: FilterParamProps[];
  orderFilters: OrderFilter[];
}

const OrderFilters = ({
  onFilterSet,
  onFilterParamChange,
  onFilterValueChange,
  onRamiSmartFilterToggle,
  onMyOrdersFilterToggle,
  onOfficeChange,
  onResetFilterClick,
  onFilterDelete,
  filterValue,
  ramiSmartFilter,
  myOrdersFilter,
  officeList,
  officeFilter,
  filterParams,
  orderFilters
}: OrderFiltersParams) => {
  const classes = useStyles();
  const isFilters = orderFilters.length > 0;
  const showOrderForm = localStorage.getItem('showOrderForm') === 'true';

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Select
            id="FilterParamSelect"
            native
            onChange={(e) => {
              onFilterParamChange(e.target.value as FilterParam);
            }}
          >
            {filterParams.map((filterParam) => (
              <option key={filterParam.value} value={filterParam.value}>
                {filterParam.label}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <TextField
            inputProps={{ 'data-cy': 'filter-value-input' }}
            value={filterValue}
            className={classes.filterValueInput}
            onChange={(e) => {
              onFilterValueChange(e.target.value.toString());
            }}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                onFilterSet();
              }
            }}
          />
        </Grid>
        <Grid item>
          <Button
            data-cy="set-filter-button"
            variant="contained"
            color="primary"
            onClick={() => {
              if (filterValue.length > 0) {
                onFilterSet();
              }
            }}
          >
            Suodata
          </Button>
        </Grid>
        <Grid item>
          <Button
            data-cy="reset-filters-button"
            variant="contained"
            disabled={!isFilters}
            onClick={onResetFilterClick}
          >
            Tyhjennä
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        alignItems="center"
        className={classes.filterContainer}
      >
        <Grid
          container
          item
          xs={8}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.filterRow}
        >
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  name="myOrderFilter"
                  color="primary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onMyOrdersFilterToggle(event.target.checked)
                  }
                  checked={myOrdersFilter}
                />
              }
              label="Täyttämäni tilaukset"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  name="ramiSmartFilter"
                  color="primary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onRamiSmartFilterToggle(event.target.checked)
                  }
                  checked={ramiSmartFilter}
                />
              }
              label="RamiSmart"
            />
          </Grid>
          <Grid item>
            <Autocomplete
              id="OfficesSelect"
              className={classes.autoCompleteOffice}
              value={mapOfficeFilter(officeFilter)}
              disableClearable={true}
              onChange={(e, selected) => {
                onOfficeChange(selected?.value);
              }}
              getOptionSelected={(o, value) => {
                return value.label === o.label && value.value === o.value;
              }}
              options={[
                {
                  value: 'ShowAllOffices',
                  label: 'Kaikki toimipisteet'
                }
              ].concat(officeList)}
              getOptionLabel={(o) => {
                return o.label;
              }}
              renderInput={(params: any) => (
                <TextField {...params} margin="normal" />
              )}
            />
          </Grid>
          <Grid container>
            <Grid data-cy="filters-container">
              {orderFilters.map((orderFilter) => (
                <Chip
                  key={orderFilter.param}
                  label={`${getFilterLabel(orderFilter)}: ${orderFilter.value}`}
                  onDelete={() => onFilterDelete(orderFilter)}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.newOrderButton}>
          <RouterLink
            className={`${classes.link} ${
              !showOrderForm ? classes.disabled : ''
            }`}
            target="_blank"
            to={`/tilaustieto`}
            onClick={(event: any) => {
              if (!showOrderForm) {
                event.preventDefault();
              }
            }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={!showOrderForm}
            >
              Täytä uusi tilaus
            </Button>
          </RouterLink>
        </Grid>
      </Grid>
      {showOrderForm === false ? <OrderFormRedirectMessage /> : null}
    </>
  );
};

export default OrderFilters;
