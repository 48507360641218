import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import faker from 'faker';
import ProductTable from './ProductTable';
import {
  clone,
  getLabel,
  ramiSafeOptions,
  selectOrderCopyEmail,
  useLongPress,
  usePreventWindowUnload,
  isMobile,
  changeObserver
} from '../utils';
import { getCustomList, sendTTL } from '../requests';
import ContentContainer from '../ContentContainer';
import { DateTime } from 'luxon';
import { getUserDataStorage } from '../storageHelper';
import { DataList, DataListEntry, TtlFormInput } from '../types';
import SubmitDialog from './SubmitDialog';
import ContentWrapper from '../ContentWrapper';
import MobileProductAdd from './MobileProductAdd';
import { Autocomplete } from '@material-ui/lab';
import SendTtlErrorDialog from './SendTtlErrorDialog';
import { Prompt } from 'react-router-dom';
import { EnvContext } from '../App';
import { omit } from 'lodash';
import OrderFormRedirectMessage from './OrderFormRedirectMessage';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiFormControl-root': {
        margin: theme.spacing(1)
      },
      '& .MuiFormControlLabel-root': {
        color: theme.palette.primary.main
      }
    },
    wideField: {
      width: '250px'
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
    overflowX: {
      overflowX: 'auto'
    },
    officeField: {
      display: 'inline-block',
      marginRight: theme.spacing(2)
    },
    fullWidth: {
      width: '100%'
    }
  })
);

const newRowTemplate = {
  id: '',
  name: '',
  ramisafe: 'eiRamiTurvaa',
  unit: 'kpl',
  price: '',
  permonth: false,
  info: '',
  amount: undefined,
  offerprice: false
};

interface ProductTableProps {
  id: string;
  name: string;
  ramisafe: 'eiRamiTurvaa' | 'ramiTurva' | 'ramiTurvaAov';
  unit: string;
  price: string;
  permonth: boolean;
  info: string;
  amount: string;
  offerprice: boolean;
}

const unitOptions = ['kpl', 'm', 'rulla', 'litra', 'paketti'];

type ValidatableFields = 'id' | 'unit' | 'name';

const hasValue =
  (productTableProps: ProductTableProps) => (field: ValidatableFields) => {
    return productTableProps[field].length > 0;
  };

// validates if product amount is either empty (undefined) or larger than 0. Zero values are not allowed.
const isValidProductAmount = (amount: string | undefined) => {
  if (typeof amount === 'undefined') {
    return true;
  } else {
    const maybeAmount = parseInt(amount, 10);
    return maybeAmount > 0;
  }
};

const isValidProduct = (productTableProps: ProductTableProps) => {
  const validator = hasValue(productTableProps);
  return (
    (['id', 'name'] as ValidatableFields[]).some(validator) &&
    validator('unit') &&
    isValidProductAmount(productTableProps.amount)
  );
};

const isWeekday = (day: number) => {
  return day >= 1 && day < 6;
};

const isSaturday = (day: number) => day === 6;
const isSunday = (day: number) => day === 7;

const weekdays = ['Ma', 'Ti', 'Ke', 'To', 'Pe', 'La', 'Su'];

const generateQuickSelectDays = (
  onClickHandler: (targetDate: string) => void
) => {
  let isOverWeekend = false;
  return [1, 2, 3].map((dateIncrement) => {
    let date: DateTime = DateTime.local();
    const targetDate = date.plus({ day: dateIncrement });
    if (isWeekday(targetDate.weekday)) {
      date = targetDate.plus({ day: isOverWeekend ? 2 : 0 });
    } else if (isSaturday(targetDate.weekday)) {
      date = targetDate.plus({ day: 2 });
      isOverWeekend = true;
    } else if (isSunday(targetDate.weekday)) {
      date = targetDate.plus({ day: 2 });
      isOverWeekend = true;
    }
    return (
      <Chip
        key={dateIncrement}
        size="small"
        label={weekdays[date.weekday - 1] + ' ' + date.toFormat('dd.MM')}
        onClick={() => {
          onClickHandler(date.toFormat('yyyy-MM-dd'));
        }}
      />
    );
  });
};

const randomArrayEntry = <T,>(arr: T[]): T => {
  const len = arr.length;
  return arr[Math.floor(Math.random() * len)];
};

const IAmSeller = (
  className: string,
  checked: boolean,
  setSelfAsSeller: () => void,
  unsetSelfSeller: () => void
) => {
  return (
    <>
      <FormControl className={className}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              color="primary"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setSelfAsSeller();
                } else {
                  unsetSelfSeller();
                }
              }}
            />
          }
          label="Olen myyjä"
        />
      </FormControl>
    </>
  );
};

const defaultValues: TtlFormInput = {
  customerName: '',
  customerNumber: '',
  orderId: '',
  contractId: '',
  siteId: '',
  siteName: '',
  deliveryAddress: '',
  town: '',
  deliveryInfo: '',
  orderer: '',
  ordererPhone: '',
  ordererEmail: '',
  deliveryType: '',
  recipient: '',
  recipientPhone: '',
  seller: '',
  sellerPhone: '',
  office: '',
  returnDate: '',
  capabilityConfirmed: false,
  deliveryPrice: 'Kuljetushinnaston mukaisesti',
  fastDelivery: false,
  requiresInstallation: false,
  info: '',
  orderCopyEmails: {
    label: 'Asiakaspalvelu',
    value: 'asiakaspalvelu@ramirent.fi'
  },
  products: [],
  postalCode: '',
  siteKey: '',
  deliveryEarliestDate: '',
  deliveryEarliestTime: '',
  deliveryLatestDate: '',
  deliveryLatestTime: '',
  sellerEmail: '',
  confirmationToSeller: false,
  confirmationToCustomer: false,
  manualPrice: ''
};

const hasUnsavedChanges = (dirtyFields: object) =>
  Object.keys(dirtyFields).length > 0;

const [watchObserver, resetFormFillDuration] = changeObserver(15000);

const OrderForm = ({ preFilledValues }: { preFilledValues: TtlFormInput }) => {
  const [formOpenedAt, setFormOpenedAt] = React.useState(new Date());
  const methods = useForm({ defaultValues: preFilledValues || defaultValues });
  const classes = useStyles();
  const isMobileView = useMediaQuery('(max-width:580px)');
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    errors,
    reset,
    formState,
    watch
  } = methods;
  const formFillDurationGetter = watchObserver(
    watch(Object.keys(omit(defaultValues, ['manualPrice'])))
  );
  const [showDeliveryPriceField, setShowDeliveryPriceField] =
    React.useState(false);
  const [showAddNewProductDialog, setShowAddNewProductDialog] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [ttlOffices, setTtlOffices] = React.useState<DataListEntry[]>([]);
  const [ttlSendToOffice, setTtlSendToOffice] = React.useState<DataListEntry[]>(
    []
  );
  const [showSuccessDialog, setShowSuccessDialog] = React.useState(false);
  const [showErrorDialog, setShowErrorDialog] = React.useState(false);
  const [orderApiError, setOrderApiError] = React.useState('');
  const [emailList, setEmailList] = React.useState<
    { email: string; label: string }[]
  >([]);
  const [iAmSellerChecked, setIAmSellerChecked] = React.useState(false);
  const showOrderForm = localStorage.getItem('showOrderForm') === 'true';
  usePreventWindowUnload(
    isLoading ||
      showAddNewProductDialog ||
      hasUnsavedChanges(formState.dirtyFields)
  );
  const onSubmit = async (data: any, e: any) => {
    const isValid = window.confirm(
      `Olet lähettämässä tilauksen osoitteeseen ${selectOrderCopyEmail(
        data.orderCopyEmails
      )}`
    );
    if (isValid) {
      try {
        setIsLoading(true);
        setEmailList([
          { email: data.sellerEmail, label: 'Myyjä' },
          { email: data.ordererEmail, label: 'Asiakas' },
          {
            email: selectOrderCopyEmail(data.orderCopyEmails),
            label: 'Tilauksen käsittelijä'
          }
        ]);
        await sendTTL(data, {
          filledDurationSeconds: formFillDurationGetter(),
          formOpenedAt: formOpenedAt.toISOString()
        });
        setShowSuccessDialog(true);
        reset(defaultValues);
        setIAmSellerChecked(false);
        setShowDeliveryPriceField(false);
        setFormOpenedAt(new Date());
        resetFormFillDuration();
      } catch (e: any) {
        setOrderApiError(JSON.stringify(e.message));
        setShowErrorDialog(true);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const setDebugValues = () => {
    const { email } = getUserDataStorage();
    setValue('customerName', faker.name.findName());
    setValue(
      'customerNumber',
      faker.datatype.number(1000 * 1000 * 10).toString()
    );
    setValue('orderId', faker.datatype.number(1000 * 1000 * 10).toString());
    setValue('contractId', faker.datatype.number(1000 * 1000 * 10).toString());
    setValue('siteId', faker.datatype.number(1000 * 1000 * 10).toString());
    setValue(
      'deliveryAddress',
      `${faker.address.streetName()} ${faker.datatype.number(
        150
      )} ${String.fromCharCode(
        65 + Math.floor(Math.random() * 26)
      )} ${faker.datatype.number(50)}`
    );
    setValue('town', faker.address.city());
    setValue('orderer', faker.name.findName());
    setValue('ordererPhone', faker.phone.phoneNumber());
    setValue('ordererEmail', email);
    setValue('seller', faker.name.findName());
    setValue('sellerEmail', email);
    setValue('sellerPhone', faker.phone.phoneNumber());
    setValue('deliveryEarliestDate', DateTime.local().toFormat('yyyy-MM-dd'));
    setValue('deliveryLatestDate', DateTime.local().toFormat('yyyy-MM-dd'));
    setValue('office', randomArrayEntry(ttlOffices).label);
    setValue('returnDate', DateTime.local().toFormat('yyyy-MM-dd'));
    const p = clone(newRowTemplate);
    p.id = '1234';
    p.name = 'nosturi';
    p.ramisafe = 'ramiTurva';
    p.unit = 'kpl';
    p.price = '123';
    p.permonth = false;
    p.offerprice = false;
    p.info = 'testi testersson';
    setValue('products', clone([p, p]));
    setValue('deliveryPrice', 'Kuljetushinnaston mukaisesti');
    setValue('deliveryType', 'Asiakas noutaa');
    setValue('orderCopyEmails', {
      label: 'Kehitystestaus',
      value: 'kehitystestaus@ramirent.fi'
    });
  };

  const setSelfAsSeller = () => {
    setIAmSellerChecked(true);
    const { firstName, lastName, email, phoneNumber } = getUserDataStorage();
    setValue('seller', `${firstName} ${lastName}`);
    setValue('sellerEmail', `${email}`);
    setValue('sellerPhone', `${phoneNumber}`);
  };

  const unsetSelfSeller = () => {
    setIAmSellerChecked(false);
    setValue('seller', '');
    setValue('sellerEmail', '');
    setValue('sellerPhone', '');
  };

  const handleNewProductClick = () => {
    if (isMobile()) {
      setShowAddNewProductDialog(true);
    } else {
      const currProducts = clone(Array.from(getValues('products')));
      currProducts.push(clone<any>(newRowTemplate));
      setValue('products', currProducts);
    }
  };
  const envs = useContext(EnvContext);
  React.useEffect(() => {
    const commonListDbIds = envs?.TTL_LISTS?.split(',').map(Number) ?? [];
    if (commonListDbIds.length === 0) {
      return;
    }
    Promise.all<DataList>(commonListDbIds.map(getCustomList))
      .then(([ttlOfficeList, ttlSendToOfficeList]) => {
        setTtlOffices(ttlOfficeList.data);
        setTtlSendToOffice(ttlSendToOfficeList.data);
      })
      .catch((err) => {
        setShowErrorDialog(true);
        setOrderApiError(
          'Toimipistetietoja ei voitu ladata. Lomakkeen täyttäminen ei onnistu.'
        );
        console.error(err);
      });
  }, [envs]);
  const handleLongPress = useLongPress(setDebugValues);
  type CodeKeyboardEvent<T> = React.KeyboardEvent<T> & { code: string };
  return (
    <>
      {showOrderForm === false ? (
        <ContentContainer>
          <OrderFormRedirectMessage />
        </ContentContainer>
      ) : (
        <>
          <Prompt
            when={
              isLoading ||
              showAddNewProductDialog ||
              hasUnsavedChanges(formState.dirtyFields)
            }
            message="Lomakkeella on tallentamattomia muutoksia. Oletko varma, että haluat poistua?"
          />
          <form
            className={classes.root}
            onSubmit={handleSubmit(onSubmit)}
            onKeyPress={(e: CodeKeyboardEvent<HTMLFormElement>) => {
              if (e.code === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            <ContentWrapper>
              <h2 {...handleLongPress} color="primary">
                Tilaustietolomake
              </h2>
            </ContentWrapper>
            <ContentContainer>
              <Typography variant="h5" color="primary">
                Asiakastiedot
              </Typography>
              <Controller
                rules={{ required: true }}
                render={(props) => {
                  return (
                    <FormControl
                      className={classes.wideField}
                      error={!!errors[props.name]}
                    >
                      <InputLabel>Asiakkaan nimi*</InputLabel>
                      <Input {...props} />
                    </FormControl>
                  );
                }}
                name="customerName"
                control={control}
              />
              <Controller
                render={(props) => {
                  return (
                    <FormControl className={classes.wideField}>
                      <InputLabel>{getLabel('Asiakasnumero')}</InputLabel>
                      <Input {...props} />
                    </FormControl>
                  );
                }}
                name="customerNumber"
                control={control}
              />
              <Controller
                render={(props) => {
                  return (
                    <FormControl className={classes.wideField}>
                      <InputLabel>{getLabel('Tilausnumero')}</InputLabel>
                      <Input {...props} />
                    </FormControl>
                  );
                }}
                name="orderId"
                control={control}
              />
              <Controller
                render={(props) => {
                  return (
                    <FormControl className={classes.wideField}>
                      <InputLabel>{getLabel('Myyntitilausnumero')}</InputLabel>
                      <Input {...props} />
                    </FormControl>
                  );
                }}
                name="contractId"
                control={control}
              />
            </ContentContainer>
            <ContentContainer>
              <Typography variant="h5" color="primary">
                Työmaatiedot
              </Typography>
              <Controller
                rules={{ required: true }}
                render={(props) => {
                  return (
                    <FormControl
                      className={classes.wideField}
                      error={!!errors[props.name]}
                    >
                      <InputLabel>{getLabel('Työnumero*')}</InputLabel>
                      <Input {...props} />
                    </FormControl>
                  );
                }}
                name="siteId"
                control={control}
              />
              <Controller
                render={(props) => {
                  return (
                    <FormControl className={classes.wideField}>
                      <InputLabel>Työmaan nimi</InputLabel>
                      <Input {...props} id="site_name" />
                    </FormControl>
                  );
                }}
                name="siteName"
                control={control}
              />
              <Controller
                render={(props) => {
                  return (
                    <FormControl
                      className={classes.wideField}
                      error={!!errors[props.name]}
                    >
                      <InputLabel>Toimitusosoite*</InputLabel>
                      <Input {...props} />
                    </FormControl>
                  );
                }}
                name="deliveryAddress"
                control={control}
                rules={{ required: true }}
              />
              <Controller
                render={(props) => {
                  return (
                    <FormControl className={classes.wideField}>
                      <InputLabel>Postinumero</InputLabel>
                      <Input {...props} />
                    </FormControl>
                  );
                }}
                name="postalCode"
                control={control}
              />
              <Controller
                render={(props) => {
                  return (
                    <FormControl
                      className={classes.wideField}
                      error={!!errors[props.name]}
                    >
                      <InputLabel>Paikkakunta*</InputLabel>
                      <Input {...props} />
                    </FormControl>
                  );
                }}
                name="town"
                control={control}
                rules={{ required: true }}
              />
              <Controller
                render={(props) => {
                  return (
                    <FormControl className={classes.wideField}>
                      <InputLabel>Työmaa-avain</InputLabel>
                      <Input {...props} />
                    </FormControl>
                  );
                }}
                name="siteKey"
                control={control}
              />

              <Controller
                render={(props) => {
                  return (
                    <FormControl className={classes.wideField}>
                      <InputLabel>Toimituksen huomiot</InputLabel>
                      <Input {...props} id="toim_huom" />
                    </FormControl>
                  );
                }}
                name="deliveryInfo"
                control={control}
              />
            </ContentContainer>
            <ContentContainer>
              <Box>
                <Typography variant="h5" color="primary">
                  Toimitustiedot
                </Typography>
                <Box
                  display="inline-flex"
                  flexDirection="column"
                  className={classes.wideField}
                >
                  <Controller
                    render={(props) => {
                      return (
                        <FormControl error={!!errors[props.name]}>
                          <InputLabel shrink>
                            Toimituspäivä aikasintaan*
                          </InputLabel>
                          <Input {...props} type="date" />
                        </FormControl>
                      );
                    }}
                    name="deliveryEarliestDate"
                    control={control}
                    rules={{ required: true }}
                  />
                  <div>
                    {generateQuickSelectDays((dateString) => {
                      setValue('deliveryEarliestDate', dateString);
                    })}
                  </div>
                </Box>
                <Controller
                  render={(props) => {
                    return (
                      <FormControl className={classes.wideField}>
                        <InputLabel shrink>Toimitusaika aikasintaan</InputLabel>
                        <Input {...props} type="time" />
                      </FormControl>
                    );
                  }}
                  name="deliveryEarliestTime"
                  control={control}
                />
                <Box
                  display="inline-flex"
                  flexDirection="column"
                  className={classes.wideField}
                >
                  <Controller
                    render={(props) => {
                      return (
                        <FormControl error={!!errors[props.name]}>
                          <InputLabel shrink>
                            Toimituspäivä viimeistään*
                          </InputLabel>
                          <Input {...props} type="date" />
                        </FormControl>
                      );
                    }}
                    name="deliveryLatestDate"
                    control={control}
                    rules={{ required: true }}
                  />
                  <div>
                    {generateQuickSelectDays((dateString) => {
                      setValue('deliveryLatestDate', dateString);
                    })}
                  </div>
                </Box>
                <Controller
                  render={(props) => {
                    return (
                      <FormControl className={classes.wideField}>
                        <InputLabel shrink>Toimitusaika viimeistään</InputLabel>
                        <Input {...props} type="time" />
                      </FormControl>
                    );
                  }}
                  name="deliveryLatestTime"
                  control={control}
                />
              </Box>
              <Box>
                <Controller
                  render={(props) => {
                    return (
                      <FormControl
                        className={classes.wideField}
                        error={!!errors[props.name]}
                      >
                        <InputLabel>Tilaaja (asiakas)*</InputLabel>
                        <Input {...props} type="text" />
                      </FormControl>
                    );
                  }}
                  name="orderer"
                  control={control}
                  rules={{ required: true }}
                />
                <Controller
                  render={(props) => {
                    return (
                      <FormControl
                        className={classes.wideField}
                        error={!!errors[props.name]}
                      >
                        <InputLabel>Tilaajan puhelin (asiakas)*</InputLabel>
                        <Input {...props} type="text" />
                      </FormControl>
                    );
                  }}
                  name="ordererPhone"
                  control={control}
                  rules={{ required: true }}
                />
                <Controller
                  render={(props) => {
                    return (
                      <FormControl
                        className={classes.wideField}
                        error={!!errors[props.name]}
                      >
                        <InputLabel id="id-ordererEmail">
                          Tilaajan sähköposti (asiakas)*
                        </InputLabel>
                        <Input {...props} type="email" />
                      </FormControl>
                    );
                  }}
                  name="ordererEmail"
                  control={control}
                  rules={{ required: true }}
                />
              </Box>
              <Box>
                <Controller
                  render={(props) => {
                    return (
                      <FormControl
                        className={classes.wideField}
                        error={!!errors[props.name]}
                      >
                        <InputLabel>Toimitustapa*</InputLabel>
                        <Select {...props}>
                          <MenuItem value="Asiakas noutaa">
                            Asiakas noutaa
                          </MenuItem>
                          <MenuItem value="Toimitetaan asiakkaalle">
                            Toimitetaan asiakkaalle
                          </MenuItem>
                          <MenuItem value="Toimitetaan asiakkaalle (ei Postilla)">
                            Toimitetaan asiakkaalle (ei Postilla)
                          </MenuItem>
                          <MenuItem value="Toimitetaan toimipisteelle">
                            Toimitetaan toimipisteelle
                          </MenuItem>
                          <MenuItem value="Työmaapalvelut">
                            Työmaapalvelut
                          </MenuItem>
                          <MenuItem value="Varastosiirto">
                            Varastosiirto
                          </MenuItem>
                        </Select>
                      </FormControl>
                    );
                  }}
                  name="deliveryType"
                  control={control}
                  rules={{ required: true }}
                />
                <Controller
                  render={(props) => {
                    return (
                      <FormControl className={classes.wideField}>
                        <InputLabel>Toimituksen vastaanottaja</InputLabel>
                        <Input {...props} />
                      </FormControl>
                    );
                  }}
                  name="recipient"
                  control={control}
                />
                <Controller
                  render={(props) => {
                    return (
                      <FormControl className={classes.wideField}>
                        <InputLabel>Vastaanottajan puhelin</InputLabel>
                        <Input {...props} />
                      </FormControl>
                    );
                  }}
                  name="recipientPhone"
                  control={control}
                />
              </Box>
              <Box>
                {isMobileView &&
                  IAmSeller(
                    classes.wideField,
                    iAmSellerChecked,
                    setSelfAsSeller,
                    unsetSelfSeller
                  )}
                <Controller
                  render={(props) => {
                    return (
                      <FormControl
                        className={classes.wideField}
                        error={!!errors[props.name]}
                      >
                        <InputLabel>Myyjä*</InputLabel>
                        <Input {...props} />
                      </FormControl>
                    );
                  }}
                  name="seller"
                  control={control}
                  rules={{ required: true }}
                />
                <Controller
                  render={(props) => {
                    return (
                      <FormControl
                        className={classes.wideField}
                        error={!!errors[props.name]}
                      >
                        <InputLabel>Myyjän puhelin*</InputLabel>
                        <Input {...props} />
                      </FormControl>
                    );
                  }}
                  name="sellerPhone"
                  control={control}
                  rules={{ required: true }}
                />
                <Controller
                  render={(props) => {
                    return (
                      <FormControl
                        className={classes.wideField}
                        error={!!errors[props.name]}
                      >
                        <InputLabel>Myyjän sähköposti*</InputLabel>
                        <Input {...props} type="email" />
                      </FormControl>
                    );
                  }}
                  name="sellerEmail"
                  control={control}
                  rules={{ required: true }}
                />
                {!isMobileView &&
                  IAmSeller(
                    classes.wideField,
                    iAmSellerChecked,
                    setSelfAsSeller,
                    unsetSelfSeller
                  )}
              </Box>
              <Box>
                <Controller
                  render={(props) => {
                    return (
                      <Autocomplete
                        className={
                          classes.wideField + ' ' + classes.officeField
                        }
                        value={props.value}
                        onChange={(e, newInputValue) => {
                          props.onChange(newInputValue);
                        }}
                        options={ttlOffices
                          .map((office) => office.label)
                          .concat([''])} // default value is empty
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Vastuullinen toimipiste*"
                            margin="normal"
                            error={!!errors[props.name]}
                          />
                        )}
                      />
                    );
                  }}
                  name="office"
                  control={control}
                  rules={{ required: true }}
                />
                <Controller
                  render={(props) => {
                    return (
                      <FormControl
                        className={classes.wideField}
                        error={!!errors[props.name]}
                      >
                        <InputLabel shrink>Arvioitu palautuspäivä*</InputLabel>
                        <Input {...props} type="date" />
                      </FormControl>
                    );
                  }}
                  name="returnDate"
                  control={control}
                  rules={{ required: true }}
                />
              </Box>
              <Box>
                <Controller
                  render={(props) => {
                    return (
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={props.name}
                              checked={!!props.value}
                              color="primary"
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          }
                          label="Toimituskyky varmistettu"
                        />
                      </FormControl>
                    );
                  }}
                  name="capabilityConfirmed"
                  control={control}
                />
                <Controller
                  render={(props) => {
                    return (
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={props.name}
                              checked={!!props.value}
                              color="primary"
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          }
                          label="Toimitusvahvistus myyjälle"
                        />
                      </FormControl>
                    );
                  }}
                  name="confirmationToSeller"
                  control={control}
                />
                <Controller
                  render={(props) => {
                    return (
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={props.name}
                              checked={!!props.value}
                              color="primary"
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          }
                          label="Toimitusvahvistus asiakkaalle"
                        />
                      </FormControl>
                    );
                  }}
                  name="confirmationToCustomer"
                  control={control}
                />
              </Box>
            </ContentContainer>
            <ContentContainer className={classes.overflowX}>
              <Controller
                rules={{
                  validate: (productTableValue) => {
                    return productTableValue.every(isValidProduct);
                  }
                }}
                render={(props) => {
                  return (
                    <ProductTable
                      unitOptions={unitOptions}
                      ramisafeOptions={ramiSafeOptions}
                      error={!!errors[props.name]}
                      value={props.value}
                      onChange={props.onChange}
                      handleNewProductClick={handleNewProductClick}
                    />
                  );
                }}
                name="products"
                control={control}
              />
            </ContentContainer>
            <ContentContainer>
              <Controller
                render={(props) => {
                  const changeHandler = (event: any) => {
                    if (event.target.value === 'Hinta käsin (per suunta)') {
                      setShowDeliveryPriceField(true);
                    } else {
                      setShowDeliveryPriceField(false);
                    }
                    props.onChange(event);
                  };
                  return (
                    <FormControl
                      className={classes.wideField}
                      error={!!errors[props.name]}
                    >
                      <InputLabel id="id-delivery-price">
                        Sovittu kuljetusveloitus *
                      </InputLabel>
                      <Select
                        labelId="id-delivery-price"
                        id="id-delivery-price"
                        {...props}
                        onChange={changeHandler}
                      >
                        <MenuItem value="Kuljetushinnaston mukaisesti">
                          Kuljetushinnaston mukaisesti
                        </MenuItem>
                        <MenuItem value="Hinta käsin (per suunta)">
                          Hinta käsin
                        </MenuItem>
                        <MenuItem value="Ei kuljetusveloitusta">
                          Ei kuljetusveloitusta
                        </MenuItem>
                      </Select>
                    </FormControl>
                  );
                }}
                name="deliveryPrice"
                control={control}
                required={{ required: true }}
              />
              {showDeliveryPriceField && (
                <Controller
                  render={(props) => {
                    return (
                      <FormControl className={classes.wideField}>
                        <InputLabel shrink>
                          Hinta käsin (per suunta), alv 0%
                        </InputLabel>
                        <Input
                          {...props}
                          inputProps={{ type: 'number', step: '0.01' }}
                        />
                      </FormControl>
                    );
                  }}
                  name="manualPrice"
                  control={control}
                />
              )}
              <Box>
                <Controller
                  render={(props) => {
                    return (
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={props.name}
                              checked={!!props.value}
                              color="primary"
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          }
                          label="Pikatoimituslisä"
                        />
                      </FormControl>
                    );
                  }}
                  name="fastDelivery"
                  control={control}
                />
                <Controller
                  render={(props) => {
                    return (
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={props.name}
                              checked={!!props.value}
                              color="primary"
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          }
                          label="Toimitus vaatii asennusta"
                        />
                      </FormControl>
                    );
                  }}
                  name="requiresInstallation"
                  control={control}
                />
              </Box>
              <Box>
                <Controller
                  render={(props) => {
                    return (
                      <FormControl className={classes.fullWidth}>
                        <TextField
                          {...props}
                          type="text"
                          multiline={true}
                          minRows={2}
                          variant="outlined"
                          label="Lisätietoja tilauksesta"
                        />
                      </FormControl>
                    );
                  }}
                  name="info"
                  control={control}
                />
              </Box>
              <Controller
                render={(props) => {
                  return (
                    <Autocomplete
                      className={classes.wideField}
                      value={props.value}
                      onChange={(e, newInputValue) => {
                        props.onChange(newInputValue);
                      }}
                      getOptionSelected={(o, value) => {
                        return (
                          value.label === o.label && value.value === o.value
                        );
                      }}
                      getOptionLabel={(o) => o.label}
                      options={ttlSendToOffice}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label="Lähetä tilaus osoitteeseen*"
                          margin="normal"
                          error={!!errors[props.name]}
                        />
                      )}
                    />
                  );
                }}
                name="orderCopyEmails"
                control={control}
                rules={{ required: true }}
              />
              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  data-cy="submit-ttl"
                >
                  Lähetä lomake
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
                <Button
                  style={{ display: 'none' }}
                  type="button"
                  onClick={setDebugValues}
                  data-cy="debug-filler-btn"
                >
                  Prefill values
                </Button>
              </Box>
            </ContentContainer>
          </form>
          <MobileProductAdd
            unitOptions={unitOptions}
            ramisafeOptions={ramiSafeOptions}
            show={showAddNewProductDialog}
            handleDismiss={() => {
              setShowAddNewProductDialog(false);
            }}
            handleProductAdd={(newProduct: any) => {
              setValue('products', getValues('products').concat(newProduct));
            }}
            newRowTemplate={newRowTemplate}
          />

          <SubmitDialog
            show={showSuccessDialog}
            handleDismiss={() => {
              setShowSuccessDialog(false);
            }}
            headerText="Tilaus lähetetty onnistuneesti!"
            leavePageButtonText="OK"
            status="success"
          >
            {emailList.length > 0 && (
              <>
                <Typography>Sähköpostit lähtevät osoitteisiin</Typography>
                <List component="nav">
                  {emailList.map((em, i) => (
                    <span key={em.email + i}>
                      <ListItem>
                        <ListItemText primary={em.email} secondary={em.label} />
                      </ListItem>
                      <Divider />
                    </span>
                  ))}
                </List>
              </>
            )}
          </SubmitDialog>

          <SendTtlErrorDialog
            show={showErrorDialog}
            handleDismiss={() => {
              setShowErrorDialog(false);
            }}
          >
            <Box marginLeft={2}>
              <p style={{ fontFamily: 'monospace' }}>{orderApiError}</p>
            </Box>
          </SendTtlErrorDialog>
        </>
      )}
    </>
  );
};

export default OrderForm;
