import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const OrderFormRedirectMessage = () => {
  // THIS CAN BE DELETED AFTER ORDERFORM IS WORKING IN RAMISMART
  // AND RAMIFORMS IS NOT USED FOR ORDERFORMS ANYMORE
  return (
    <Box mt={4} mb={2}>
      <Alert severity="warning">
        <AlertTitle>Huomio</AlertTitle>
        Tilaustietolomakkeiden täyttäminen on siirretty. Jatkossa
        tilaustietolomakkeet täytetään osoitteessa:{' '}
        <a
          href="https://ramismart.fi/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.ramismart.fi
        </a>
      </Alert>
    </Box>
  );
};

export default OrderFormRedirectMessage;
