import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  LinearProgress,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { clone, isMobile } from '../../../utils';
import Delete from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { sendFileStream } from '../../../requests';
import * as Sentry from '@sentry/react';
import jwt_decode from 'jwt-decode';

const isValidFileCount = (filesMax: number, filesTotal: number) =>
  filesTotal <= filesMax;
const isValidFileSize = (file: File, maxBytes: number) => file.size <= maxBytes;

type UploadedFile = {
  fileType: string;
  url: string;
};

const getFileExtension = (file: File) =>
  file.name.split('.').pop() ?? file.name;

const renderableImageTypes = ['image/jpg', 'image/png', 'image/jpeg'];
export const isRenderableImage = (fileType: string) =>
  renderableImageTypes.indexOf(fileType) !== -1;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileListWrapper: {
      display: 'flex',
      overflowY: 'auto',
      flexDirection: isMobile() ? 'column' : 'row',
      paddingTop: 30
    },
    deleteButton: {
      position: 'absolute',
      top: -25,
      right: -25,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light
      }
    },
    fileContainer: {
      border: '1px solid #ccc',
      position: 'relative',
      borderRadius: theme.spacing(2),
      display: 'flex',
      width: 200,
      height: 200,
      marginRight: theme.spacing(4),
      alignItems: 'center',
      marginBottom: theme.spacing(3),
      justifyContent: 'center'
    },
    imagePreview: {
      width: 200,
      maxHeight: 200,
      borderRadius: theme.spacing(2)
    },
    hiddenFileInput: {
      display: 'none'
    },
    label: {
      color: theme.palette.primary.main
    }
  })
);

type FileToken = {
  key: string;
  fileExtension: string;
};

const getFileName = (url: string, index: number) => {
  try {
    const urlParts = url.split('/');
    const token = urlParts[urlParts.length - 1];
    const decodedToken = jwt_decode<FileToken>(token);
    const name = decodedToken?.key;
    const nameParts = name?.split('.');
    if (nameParts[0].length > 20) {
      return (
        nameParts[0].slice(0, 15) +
          '(...).' +
          nameParts[nameParts.length - 1] ?? ''
      );
    }
    return nameParts[0] + '.' + nameParts[nameParts.length - 1];
  } catch (error) {
    console.log(error);
    return `Tiedosto ${index}`;
  }
};

type UploadProgressState = {
  total: number;
  fileSizes: { [key: string]: number };
};

type UploadProgressAction =
  | {
      type: UploadProgressActions.UploadProgress;
      payload: { name: string; value: number };
    }
  | { type: UploadProgressActions.Reset };

enum UploadProgressActions {
  UploadProgress,
  Reset
}

const computeTotalSize = (sizes: { [key: string]: number }) => {
  return Object.values(sizes).reduce((prev, curr) => prev + curr);
};

const uploadProgressReducer = (
  state: UploadProgressState,
  action: UploadProgressAction
) => {
  switch (action.type) {
    case UploadProgressActions.UploadProgress:
      const newState = clone(state);
      newState.fileSizes[action.payload.name] = action.payload.value;
      newState.total = computeTotalSize(newState.fileSizes);
      return newState;
    case UploadProgressActions.Reset:
      return uploadProgressInitialState;
  }
};

const uploadProgressInitialState = {
  total: 0,
  fileSizes: {}
};

const MB_10 = 10485760;
const FileUpload: React.FC<any> = ({ label, onChange, id, value, error }) => {
  const [uploadedFiles, setUrls] = React.useState<UploadedFile[]>(
    value ? value : []
  );
  const [inputError, setInputError] = React.useState<string | null>(null);
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [state, dispatch] = React.useReducer(
    uploadProgressReducer,
    uploadProgressInitialState
  );
  const [totalSelectedFileSizes, setTotalSelectedFileSizes] = React.useState(0);
  const setUploadProgress = (fileName: string, uploadedSize: number) => {
    dispatch({
      type: UploadProgressActions.UploadProgress,
      payload: { value: uploadedSize, name: fileName }
    });
  };
  const resetUploadProgress = () => {
    dispatch({ type: UploadProgressActions.Reset });
  };
  const unsetUrl = (i: number) => () => {
    const newUrls = clone(uploadedFiles);
    newUrls.splice(i, 1);
    setUrls(newUrls);
    onChange(newUrls);
    if (inputFileRef && inputFileRef.current) {
      inputFileRef.current.value = '';
    }
  };
  // Reset when react hook reset() method is called
  useEffect(() => {
    if (value === '') {
      onChange([]);
      setUrls([]);
    }
  }, [value, onChange, setUrls]);
  const classes = useStyles();
  return (
    <>
      <Box className={classes.fileListWrapper}>
        <FormLabel component="legend">
          <Typography color={error ? 'error' : 'primary'}>{label}</Typography>
        </FormLabel>
        <div>
          {uploadedFiles.map((file, i, a) => {
            return (
              <Box key={file.url} className={classes.fileContainer}>
                {isRenderableImage(file.fileType) ? (
                  <img
                    width={200}
                    src={file.url}
                    key={file.url}
                    alt={`image_${i}`}
                    className={classes.imagePreview}
                  />
                ) : (
                  <div>{getFileName(file.url, i)}</div>
                )}
                <IconButton
                  onClick={unsetUrl(i)}
                  className={classes.deleteButton}
                >
                  <Delete />
                </IconButton>
              </Box>
            );
          })}
        </div>
      </Box>
      <div>{inputError}</div>
      <FormControl>
        <Button
          startIcon={<AddIcon />}
          onClick={() => {
            inputFileRef.current?.click();
          }}
        >
          Valitse tiedostot ({uploadedFiles.length})
        </Button>

        <Input
          className={classes.hiddenFileInput}
          type="file"
          inputRef={inputFileRef}
          inputProps={{ multiple: true, id }}
          onChange={async (e: any) => {
            setInputError(null);
            const maybeFiles = e.target.files;
            if (!maybeFiles) {
              return;
            }
            const files = Array.from<File>(maybeFiles);
            if (!isValidFileCount(10, files.length)) {
              setInputError('Voit lisätä enintään 10 tiedostoa');
              return;
            }
            const fileSizesValid = files.every((file: File) =>
              isValidFileSize(file, MB_10)
            );
            const totalFileSize = files.reduce((totalFileSize, file: File) => {
              return file.size + totalFileSize;
            }, 0);
            setTotalSelectedFileSizes(totalFileSize);
            if (!fileSizesValid) {
              setInputError('Tiedostokoot eivät voi ylittää 10 megatavua.');
              return;
            }
            try {
              setIsLoading(true);
              const newUploadedFiles = (
                await Promise.all(
                  files.map(async (file) => {
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('extension', getFileExtension(file));
                    const response = await sendFileStream(
                      formData,
                      (progressEvent) => {
                        setUploadProgress(file.name, progressEvent.loaded);
                      }
                    );
                    const json = response.data;
                    return {
                      url: json.url,
                      fileType: file.type
                    };
                  })
                )
              ).reduce((prev: any, json) => {
                return prev.concat([json]);
              }, []);
              const updatedFiles = uploadedFiles.concat(newUploadedFiles);
              setUrls(updatedFiles);
              onChange(updatedFiles);
            } catch (e: any) {
              Sentry.captureException(e);
              setInputError(
                'Virhe lähetettäessä tiedostoja. Palvelinvirhe: ' + e.message
              );
              console.error(e);
            } finally {
              setIsLoading(false);
              setTotalSelectedFileSizes(0);
              resetUploadProgress();
            }
          }}
        />
      </FormControl>
      {isLoading && (
        <>
          <CircularProgress size={24} />
          <LinearProgress
            variant="determinate"
            value={(state.total / totalSelectedFileSizes) * 100}
          />
          <Typography variant="subtitle2">
            Siirretään tiedostoja palvelimelle ({(state.total / 1024).toFixed()}
            / {(totalSelectedFileSizes / 1024).toFixed()} kt)
          </Typography>
        </>
      )}
    </>
  );
};

export { FileUpload };
