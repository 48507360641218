import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  Grid,
  Link,
  IconButton,
  makeStyles,
  TextField,
  Tooltip
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ContentContainer from './ContentContainer';
import { DataList, DataListEntry, LoadingState } from './types';
import {
  ramiColors,
  clone,
  useIsLoading,
  usePreventWindowUnload
} from './utils';
import {
  DropResult,
  DragDropContext,
  Droppable,
  Draggable
} from '@hello-pangea/dnd';
import { useApi } from './Forms/DynamicForms/fetchHook';
import { Clear, Email, EmailOutlined } from '@material-ui/icons';
import {
  Prompt,
  Redirect,
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom';
import { deleteList, postList } from './requests';
import { Link as RouterLink } from 'react-router-dom';
import CircularProgressIndicator from './Forms/CircularProgressIndicator';
import { Alert } from '@material-ui/lab';
import { Add, DragHandle } from '@material-ui/icons';
import Ramicrumbs from './Ramicrumbs';
import { AlertSnackbar } from './Alert';
import * as Sentry from '@sentry/react';

type LocationState = {
  listId?: number;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  ...draggableStyle,
  padding: '10px 0',
  background: isDragging ? ramiColors.ramiGray : 'white'
});

const useStyles = makeStyles((theme) =>
  createStyles({
    listContainer: {
      maxWidth: 1000,
      margin: 'auto'
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      width: '100%'
    }
  })
);

const EditableListItem: React.FC<{
  value: string;
  label: string;
  sendEmail: boolean;
  length: number;
  index: number;
  onLabelChange: (e: any) => void;
  onValueChange: (e: any) => void;
  onEmailChange: (e: any) => void;
  onAddOption: () => void;
  onDelete: () => void;
}> = ({
  index,
  value,
  label,
  length,
  sendEmail,
  onDelete,
  onLabelChange,
  onValueChange,
  onEmailChange,
  onAddOption
}) => {
  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      onAddOption();
    }
  };
  return (
    <Grid spacing={1} container direction="row" alignItems="center">
      <Grid item xs={1}>
        <DragHandle />
      </Grid>
      <Grid item xs={3} style={{ marginBottom: 16 }}>
        <TextField
          error={label.length >= 50}
          helperText={
            label.length >= 50 ? 'Maksimi määrä merkkejä käytetty' : ' '
          }
          inputProps={{ maxLength: 50 }}
          onChange={onLabelChange}
          fullWidth
          label="Näytettävä"
          value={label}
          size="medium"
          onKeyPress={onKeyPress}
          autoFocus={index === length - 1}
        />
      </Grid>
      <Grid item xs={5} style={{ marginBottom: 16 }}>
        <TextField
          error={value.length >= 500}
          helperText={
            value.length >= 500 ? 'Maksimi määrä merkkejä käytetty' : ' '
          }
          inputProps={{ maxLength: 500 }}
          onChange={onValueChange}
          fullWidth
          label="Arvo"
          value={value}
          size="medium"
          onKeyPress={onKeyPress}
        />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
        xs={2}
      >
        <Tooltip
          title={
            sendEmail ? 'Lähetetään sähköposti' : 'Ei lähetetä sähköpostia'
          }
          enterTouchDelay={0}
          placement="top"
        >
          <Checkbox
            checked={sendEmail}
            onChange={onEmailChange}
            color={'primary'}
            icon={<EmailOutlined />}
            checkedIcon={<Email />}
          />
        </Tooltip>
        <IconButton onClick={onDelete}>
          <Clear />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const parseListId = (
  listId: string | null,
  locationState: LocationState
): number | null => {
  if (listId === 'uusi') {
    return null;
  }
  if (listId === 'kopio') {
    return Number(locationState.listId);
  } else {
    return Number(listId);
  }
};

const getHeaderText = (listId: number | null) => {
  return listId === null ? 'Uusi lista' : 'Muokkaa listaa';
};

const MAX_ROWS = 100;

const EditListView = () => {
  const [listItems, setListItems] = useState<DataListEntry[]>([]);
  const [listName, setListName] = useState<string | null>(null);
  const [isSaving, saveFinished, saveStarted] = useIsLoading();
  const [deletedOptionIds, setDeletedOptionsIds] = useState<number[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [isDragging, setIsDragging] = useState(false);
  const [showFeedback, setShowFeedback] = React.useState<string | null>(null);
  const [feedbackSeverity, setFeedbackSeverity] = React.useState<
    'success' | 'error'
  >('success');
  const handleAdd = () => {
    const newState = clone(listItems);
    newState.push({
      value: '',
      label: '',
      orderId: newState.length,
      sendEmail: true
    });
    setListItems(newState);
  };

  const isArraysEquals = (arr1: DataListEntry[], arr2: DataListEntry[]) => {
    return (
      arr1.length === arr2.length && arr1.every((elem, i) => elem === arr2[i])
    );
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    setIsDragging(false);
    if (!destination) return;
    const items = Array.from(listItems);
    const [newOrder] = items.splice(source.index, 1);
    items.splice(destination.index, 0, newOrder);
    if (!isArraysEquals(items, listItems)) {
      setIsDirty(true);
    }
    setListItems(items);
  };

  const location = useLocation();
  const locationState = location.state as LocationState;

  const [listId, setListId] = useState(
    parseListId(useParams<{ listId: string }>().listId, locationState)
  );

  const hasSaveableContents = () => {
    return (
      deletedOptionIds.length > 0 || // has pending deleted rows
      listItems.some((item) => item.id === undefined) || // has unsaved, newly added rows that have not been saved
      isDirty
    );
  };
  const saveableContents = hasSaveableContents();
  usePreventWindowUnload(saveableContents);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [response, isLoading, error] = useApi<DataList>(
    `/api/list/${listId}`,
    () => listId !== null
  );
  const isCopy = window.location.pathname.includes('kopio');

  useEffect(() => {
    setIsInitialLoad(true);
  }, [response, isCopy]);

  if (error?.statusCode === 404) {
    return <Redirect to="/listat" />;
  }
  if (response && isInitialLoad) {
    setListItems(response.data);
    setListName(isCopy ? response.name + ' kopio' : response.name);
    setListId(isCopy ? null : listId);
    setIsInitialLoad(false);
  }

  const onChangeHandler = (
    i: number,
    valueChangeType: 'label' | 'value' | 'sendEmail'
  ) => {
    return (e: any) => {
      const clonedListItems = clone(listItems);
      if (valueChangeType === 'sendEmail') {
        clonedListItems[i].sendEmail = !clonedListItems[i].sendEmail;
      } else {
        clonedListItems[i][valueChangeType] = e.target.value;
      }
      setListItems(clonedListItems);
      setIsDirty(true);
    };
  };

  const handleCopy = async () => {
    setIsDirty(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleSaveList = async () => {
    saveStarted();
    let results;
    try {
      results = await postList({
        listId: listId,
        data: listItems.map((item, i) => ({
          id: item.id,
          orderId: i,
          value: item.value,
          label: item.label,
          sendEmail: item.sendEmail
        })),
        name: listName ?? '',
        deletedOptionIds
      });
      setListId(results.list);
      setListItems(results.options);
      history.push('/listat/' + results.list);
      setDeletedOptionsIds([]);
      setIsDirty(false);
      setFeedbackSeverity('success');
      setShowFeedback('Muutokset tallennettu');
    } catch (e) {
      setFeedbackSeverity('error');
      setShowFeedback('Virhe tallentaessa listaa.');
      Sentry.captureException(e);
    } finally {
      saveFinished();
    }
  };
  return (
    <ContentContainer>
      <Prompt
        when={saveableContents && isSaving !== LoadingState.IsLoading}
        message="Listalla on tallentamattomia muutoksia. Oletko varma, että haluat poistua?"
      />
      <AlertSnackbar
        severity={feedbackSeverity}
        open={showFeedback !== null}
        message={showFeedback ?? ''}
        handleClose={() => {
          setShowFeedback(null);
        }}
      />
      <Box className={classes.listContainer}>
        <Ramicrumbs
          crumbs={[
            { href: '/', label: 'Ramiforms' },
            { href: '/listat', label: 'Listat' },
            { href: '/listat/' + listId, label: getHeaderText(listId) }
          ]}
        />
        <Box marginTop={2}>
          <TextField
            required
            error={
              listName !== null &&
              (listName.length === 0 || listName?.length >= 50)
            }
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 50 }}
            label="Listan nimi"
            helperText={
              listName && listName?.length >= 50
                ? 'Maksimi määrä merkkejä käytetty'
                : 'Listan nimi näkyy valittaessa listaa'
            }
            fullWidth
            value={listName ?? ''}
            onChange={(e) => {
              setIsDirty(true);
              setListName(e.target.value);
            }}
          />
          <div className={classes.divider} />
          <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided: any) => (
                <div {...provided.DroppableProps} ref={provided.innerRef}>
                  {listItems.map((item, i) => {
                    const onDelete = () => {
                      setListItems((prev) => {
                        const newState = clone(prev);
                        newState.splice(i, 1);
                        return newState;
                      });
                      if (listItems[i].id) {
                        setDeletedOptionsIds((prev) => {
                          const newDeletedOptionIds = clone(prev);
                          newDeletedOptionIds.push(listItems[i].id as number);
                          return newDeletedOptionIds;
                        });
                      }
                    };

                    return (
                      <Draggable key={i} draggableId={i + item.value} index={i}>
                        {(provided: any, snapshot: any) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <EditableListItem
                              length={listItems.length}
                              key={i}
                              index={i}
                              value={item.value}
                              label={item.label}
                              sendEmail={item.sendEmail || false}
                              onDelete={onDelete}
                              onValueChange={onChangeHandler(i, 'value')}
                              onLabelChange={onChangeHandler(i, 'label')}
                              onEmailChange={onChangeHandler(i, 'sendEmail')}
                              onAddOption={handleAdd}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {listItems.length === 0 && (
            <Alert severity="info">
              Lista on tyhjä. Lisää rivejä listaan valitsemalla 'Lisää rivi'
            </Alert>
          )}
          {listItems.length >= MAX_ROWS && (
            <Alert severity="warning">{`Voit lisätä vain ${MAX_ROWS} riviä.`}</Alert>
          )}
          {listItems.length <= MAX_ROWS && (
            <Button
              onClick={handleAdd}
              color="primary"
              style={{ marginTop: isDragging ? 100 : 32 }}
              startIcon={<Add />}
            >
              Lisää rivi
            </Button>
          )}
          <Divider className={classes.divider} />
          <Box display="flex" justifyContent="space-between">
            <div style={{ display: 'flex', columnGap: 20 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveList}
                disabled={
                  isSaving === LoadingState.IsLoading || !saveableContents
                }
              >
                Tallenna muutokset
                <CircularProgressIndicator isLoading={isSaving} />
              </Button>
              {!['uusi', 'kopio'].some((el) =>
                window.location.pathname.includes(el)
              ) ? (
                <Link
                  component={RouterLink}
                  to={{
                    pathname: '/listat/kopio',
                    state: { listId: listId }
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSaving === LoadingState.IsLoading || isCopy}
                    onClick={() => handleCopy()}
                  >
                    Luo kopio
                  </Button>
                </Link>
              ) : null}
            </div>
            <Button
              onClick={async () => {
                const confirmResult = window.confirm(
                  'Haluatko varmasti poistaa listan?'
                );
                if (confirmResult) {
                  await deleteList(Number(listId));
                  window.location.href = '/listat';
                }
              }}
            >
              Poista lista
            </Button>
          </Box>
        </Box>
      </Box>
    </ContentContainer>
  );
};

export default EditListView;
